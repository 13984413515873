
import { Component } from 'vue-property-decorator';
import { RegistrationMixin } from '@/mixins/registration-mixin';

@Component({})
export default class ProgressButton extends RegistrationMixin {
  async prev() {
    this.$emit('prev');
  }

  async next() {
    this.$emit('next');
  }
}
