
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import moment from 'moment';
import ProjectDatetime from '@/pages/project/components/survey-period.vue';
import ProjectCategory from '@/pages/project/components/survey-category.vue';
import ProjectSurveyTitle from '@/pages/project/components/survey-title.vue';
import ProjectRegion from '@/pages/project/components/survey-region.vue';
import { InvalidMessages, ValidationMixin } from '@/mixins/validation-mixin';
import { Method } from 'axios';
import SurveyExclude from '@/pages/project/components/survey-exclude.vue';
import ProgressButton from '@/pages/project/registration/progress-button.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

interface Step1Data {
  title: string,
  startDate: string,
  endDate: string,
  category: { [key: string]: boolean },
}

interface SubmitResponse {
  surveyId: string,
}

interface ExcludeData {
  _id: string,
  title: string,
  surveyId: string,
}

@Component({
  components: {
    ProgressButton,
    SurveyExclude,
    ProjectDatetime,
    ProjectCategory,
    ProjectSurveyTitle,
    ProjectRegion,
  },
})
export default class Step1 extends mixins(RegistrationMixin, ValidationMixin) {
  @Prop() readonly project!: Step1Data;
  title = '';
  startDate = moment().toISOString();
  endDate = moment().add(30, 'd').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString();
  country: string[] = [];
  category: string[] = [];
  categoryEtc = '';
  excludeList: ExcludeData[] = [];

  apiMethod: Method = 'post';
  apiUrl = '/project/step1/';

  async created() {
    if (this.surveyId) {
      this.loading = true;
      this.regBtnDisabled = true;
      await this.load();
      this.loading = false;
      this.regBtnDisabled = false;
    }
  }

  async load() {
    try {
      const {
        title,
        startDate,
        endDate,
        category,
      } = this.project;

      this.title = title;
      this.startDate = startDate;
      this.endDate = endDate;
      this.category = Object.keys(category || []);

      this.apiMethod = 'patch';
      this.apiUrl += this.surveyId;
    } catch (e) {
      this.regSurveyId = '';
      await this.$router.replace('/project/registration');
    }
  }

  async submit() {
    this.proc(true);
    let submitResult = false;

    try {
      const { result, data, message } = await this.axios(this.apiUrl, {
        method: this.apiMethod,
        data: {
          title: this.title,
          startDate: this.startDate,
          endDate: this.endDate,
          categories: this.category,
          categoryEtc: this.categoryEtc,
          exclude: this.excludeList.map(r => r.surveyId),
        },
      });

      this.invalidMessages.splice(0, this.invalidMessages.length);

      for (const [key, value] of Object.entries(message as InvalidMessages[])) {
        Vue.set(this.invalidMessages, key, value);
      }

      if (result) {
        const { surveyId } = data as SubmitResponse;
        this.$emit('update:project', data);
        this.regSurveyId = surveyId;
      } else {
        this.validationMessages(message);
      }
      submitResult = !!result;
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
    return submitResult;
  }

  async next() {
    const result = await this.submit();
    if (result) {
      if (!this.surveyId) {
        await this.$router.replace(`/project/registration/${ this.regSurveyId }`);
        this.regStep = this.regStep + 1;
      } else {
        this.regStep = this.regStep + 1;
        this.toTop();
      }
    }
  }
}
