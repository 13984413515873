
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import ProjectSurveyTitle from '@/pages/project/components/survey-title.vue';
import ProjectDatetime from '@/pages/project/components/survey-period.vue';
import ProjectDemoGraphics, { DemoGraphic } from '@/pages/project/components/survey-demo-graphics.vue';
import ProjectSurveySpecificData from '@/pages/project/components/survey-specific-data.vue';
import ProjectSurveyDevice, { Device } from '@/pages/project/components/survey-device.vue';
import ProjectSurveyOptions from '@/pages/project/components/survey-options.vue';
import ProjectSurveyBrowser, { Browser } from '@/pages/project/components/survey-browser.vue';
import ProjectSurveyManagers from '@/pages/project/components/survey-managers.vue';
import { InvalidMessages, ValidationMixin } from '@/mixins/validation-mixin';
import ProgressButton from '@/pages/project/registration/progress-button.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

interface Step3Data {
  demoGraphic: DemoGraphic,
  country: string[],
  estimateIncidenceRate: number,
  estimateLengthOfInterview: number,
  completeCount: number,
  device: Device,
  options: { preventDuplicates: boolean },
  supplier: string[],
  title: string,
  startDate: string,
  endDate: string,
  category: { [key: string]: boolean },
  browser: Browser,
  manager: string[],
  relationSurvey: { except: { surveyId: string[] } },
}

@Component({
  components: {
    ProgressButton,
    ProjectSurveyTitle,
    ProjectDatetime,
    ProjectDemoGraphics,
    ProjectSurveySpecificData,
    ProjectSurveyDevice,
    ProjectSurveyBrowser,
    ProjectSurveyManagers,
    ProjectSurveyOptions,
  },
})
export default class Step3 extends mixins(RegistrationMixin, ValidationMixin) {
  @Prop() readonly project!: Step3Data;

  private title = '';
  private startDate = '';
  private endDate = '';
  private category: string[] = [];
  private demoGraphic: DemoGraphic | null = null;
  private country: string[] = [];
  private estimateIncidenceRate = 0;
  private estimateLengthOfInterview = 0;
  private completeCount = 0;
  private cpi = 0;
  private currency = '';
  private device: Device | null = null;
  private options: { preventDuplicates: boolean } = { preventDuplicates: false };
  private supplier: string[] = [];
  private browser: Browser | null = null;
  private manager: string[] = [];
  private exclude: string[] = [];
  private additionalInfo : {
    wantedCount: number;
    feasibleCount: number;
    CPI: number;
    managementFee: number;
    terminateFee: number;
    quotaFullFee: number;
    currency: string;
  } | null = null;

  async created() {
    this.loading = true;
    this.regBtnDisabled = true;

    await this.load();

    this.loading = false;
    this.regBtnDisabled = false;
  }

  async load() {
    try {
      const {
        title,
        startDate,
        endDate,
        category,
        demoGraphic,
        country,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        completeCount,
        device,
        options,
        supplier,
        browser,
        manager,
        relationSurvey,
      } = this.project;

      this.title = title;
      this.startDate = startDate;
      this.endDate = endDate;
      this.category = Object.keys(category || {});
      this.demoGraphic = demoGraphic;
      this.country = country;
      this.estimateLengthOfInterview = estimateLengthOfInterview;
      this.estimateIncidenceRate = estimateIncidenceRate;
      this.completeCount = completeCount;
      this.device = device;
      this.options = options;
      this.supplier = Object.keys(supplier || {});
      this.browser = browser;
      this.manager = manager.filter(r => r !== this.getUserId);
      this.exclude = relationSurvey.except.surveyId;
    } catch (e) {
      this.validationMessages(e);
    }
  }

  async submit() {
    this.proc(true);
    let submitResult = false;

    try {
      const {
        title,
        startDate,
        endDate,
        category,
        demoGraphic,
        cpi,
        currency,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        completeCount,
        device,
        options,
        supplier,
        browser,
        manager,
        exclude,
      } = this;

      const { result, data, message } = await this.axios.patch(`/project/step3/${ this.surveyId }`, {
        browser,
        manager,
        step1: {
          title,
          startDate,
          endDate,
          exclude,
          categories: category,
        },
        step2: {
          cpi,
          currency,
          completeCount,
          estimateIncidenceRate,
          estimateLengthOfInterview,
          demoGraphic,
          device,
          options,
          channel: supplier,
        },
        additionalInfo: this.additionalInfo,
      });

      this.invalidMessages.splice(0, this.invalidMessages.length);

      for (const [key, value] of Object.entries(message as InvalidMessages[])) {
        Vue.set(this.invalidMessages, key, value);
      }

      if (result) {
        this.$emit('update:project', data);
      } else {
        this.validationMessages(message);
      }

      submitResult = !!result;
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
    return submitResult;
  }

  private calcCPI(value: { cpi: number; currency: string; feasible: number; managementFee: number; quotaFullFee: number; terminateFee: number; total: number; completeCount: number; }) {
    const { currency, cpi: CPI, feasible: feasibleCount, managementFee, quotaFullFee, terminateFee, total, completeCount: wantedCount } = value;
    this.additionalInfo = {
      wantedCount,
      feasibleCount,
      CPI,
      managementFee,
      terminateFee,
      quotaFullFee,
      currency,
    };
  }
}
