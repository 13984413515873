
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationMixin } from '@/mixins/validation-mixin';
import { RegistrationMixin } from '@/mixins/registration-mixin';
import moment from 'moment';

@Component
export default class ProjectSurveyUrl extends mixins(ValidationMixin, RegistrationMixin) {
  @Prop() agree!: boolean;
  testResult = [];

  created() {
    this.testStatusCheck(true);
  }

  async testStart() {
    try {
      const { result, data } = await this.axios.post(`/test/start/${ this.surveyId }`);
      if (result) {
        const { url } = data;
        const anchor = document.createElement('a');

        anchor.href = url;
        anchor.target = '_blank';
        anchor.click();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async testStatusCheck(isInit = false) {
    this.proc(true);
    this.regBtnDisabled = true;
    const process = { result: false };

    try {
      const { result, data, message } = await this.axios.get(`/test/status-check/${ this.surveyId }`);
      if (result) {
        const { testResult } = data;
        this.testResult = testResult;
        this.regBtnDisabled = false;
        this.validationMessages(message, true)
      } else if (!isInit) {
        throw message;
      }

      process.result = result as boolean;
    } catch (e) {
      this.validationMessages(e);
    }

    this.proc(false);
    return process.result;
  }

  formatDate(date: string) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

}
