
import Component from 'vue-class-component';
import Step1 from './step1.vue';
import Step2 from './step2.vue';
import Step3 from './step3.vue';
import Step4 from './step4.vue';
import RegistrationStep from '@/pages/project/components/registration-step.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

@Component({
  components: {
    RegistrationStep,
    Step1,
    Step2,
    Step3,
    Step4,
  },
})
export default class ProjectRegistration extends RegistrationMixin {
  private loaded = false;
  private project: any = null;

  async created() {
    await this.load();
  }

  async load() {
    // :surveyId가 있을 때(List 에서 들어온 경우)
    if (this.surveyId) {
      try {
        const { result, data, message } = await this.axios.get(`/project/${ this.surveyId }`);
        if (!result) throw message;

        this.project = data;
        this.loaded = true;
      } catch (e) {
        await this.initRegistration();
        await this.$router.replace('/project/registration');
      }
    } else {
      await this.initRegistration();
      this.loaded = true;
      return;
    }
  }

  async initRegistration() {
    this.regSurveyId = '';
    this.regStep = 1;
    this.regBtnDisabled = false;
  }
}
