
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from "vue-property-decorator";

interface Countries {
  text: string;
  value: string;
  code: string;
  flag: string;
  selected: boolean;
  disabled: boolean;
}

@Component({
})
export default class ProjectRegion extends Vue {
  @PropSync('country') countrySync!: string[];
  private countries: Countries[] = [];
  private searchText: string | null = null;

  async created() {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  async load() {
    try {
      const url = `/project/countries/${ this.surveyId || 'new' }`;
      const { result, data, message } = await this.axios({
        url
      });

      if (!result) throw message

      const { countries, country } = data as { countries: Countries[], country: string[] };
      this.countries = countries;
      this.countrySync = country;
    } catch (e) {
      console.log(e);
    }
  }

  get matchedCountries() {
    return this.countries.filter(({ text }) => {
      if (this.searchText) {
        const regExp = new RegExp(this.searchText, 'gi');
        return text.match(regExp);
      }
      return true;
    });
  }


}
