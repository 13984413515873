
import { Vue, Component, PropSync } from 'vue-property-decorator';
import draggable from 'vuedraggable';

interface ExcludeData {
  _id: string,
  title: string,
  surveyId: string,
}

@Component({
  components: {
    draggable,
  },
})
export default class SurveyExclude extends Vue {
  @PropSync('excludeList') excludeListSync!: ExcludeData[];

  search = 'surveyId';
  value = '';
  searchList: ExcludeData[] = [];
  addAlert = false;

  async created() {
    if (this.surveyId) {
      this.loading = true;
      await this.load();
      this.loading = false;
    }
  }

  async load() {
    try {
      const { result, data, message } = await this.axios.get(`/project/exclude/${ this.surveyId }`);
      if (!result) throw message;

      for (const [key, value] of Object.entries(data)) {
        Vue.set(this.excludeListSync, key, value);
      }

      this.excludeListSync = data;
    } catch (e) {
      console.log(e);
    }
  }

  async excludeSearch() {
    try {
      const { result, data, message } = await this.axios.get(`/project/exclude-list`, {
        params: {
          search: this.search,
          value: this.value,
          surveyId: this.surveyId,
        },
      });

      if (!result) throw message;

      const exists = this.excludeListSync.map(r => r.surveyId);
      this.searchList = (data as ExcludeData[]).filter(r => !exists.includes(r.surveyId));
    } catch (e) {
      this.validationMessages(e);
    }
  }

  addExcludes(evt: any) {
    if (this.excludeListSync.length > 3) {
      const { element, oldIndex } = evt.removed as { element: ExcludeData, oldIndex: number };
      const newIndex = this.excludeListSync.findIndex(r => r.surveyId === element.surveyId);

      this.excludeListSync.splice(newIndex, 1);
      this.searchList.splice(oldIndex, 1, element);
      this.addAlert = true;
    }
  }
}
