
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { InvalidMessages } from '@/mixins/validation-mixin';

@Component({
})
export default class ProjectCategory extends Vue {
  @Prop() invalidMessages?: Array<InvalidMessages>;
  @Prop() invalidMessage!: (field: string) => string | null;
  @Prop() validation!: (field: string) => boolean | null;
  @Prop() supLoading!: boolean;
  @PropSync('category') categorySync!: string[];
  @PropSync('categoryEtc') categoryEtcSync!: string

  private field = 'category';
  categories: { text: string, value: string }[] = [];
  etc = false;

  async created(): Promise<void> {
    this.loading = true;
    await this.getCategory();
    this.loading = false;
  }

  async getCategory() {
    try {
      const { result, data, message } = await this.axios.get('/category')
      if (!result) throw message;

      this.categories = data.map((r: { label: string, category: string }) => {
        return {
          text: r.label,
          value: r.category,
        }
      })

      const categoryEtc = this.categorySync.filter(r => !this.categories.map(r => r.value).includes(r));
      for (const etc of categoryEtc) {
        this.categories.push({ text: etc, value: etc })
      }
    } catch (e) {
      this.validationMessages(e);
    }
  }
}
