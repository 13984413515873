
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import ProjectDemoGraphics, { DemoGraphic } from '@/pages/project/components/survey-demo-graphics.vue';
import ProjectSurveySpecificData from '@/pages/project/components/survey-specific-data.vue';
import ProjectSurveyDevice, { Device } from '@/pages/project/components/survey-device.vue';
import ProjectSurveyOptions from '@/pages/project/components/survey-options.vue';
import ProjectPanelChannel from '@/pages/project/components/panel-channel.vue';
import { InvalidMessages, ValidationMixin } from '@/mixins/validation-mixin';
import ProgressButton from '@/pages/project/registration/progress-button.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

interface Step2Data {
  demoGraphic: DemoGraphic,
  country: string[],
  estimateIncidenceRate: number,
  estimateLengthOfInterview: number,
  completeCount: number,
  device: Device,
  options: { preventDuplicates: boolean },
  supplier: string[],
}

@Component({
  components: {
    ProgressButton,
    ProjectDemoGraphics,
    ProjectSurveySpecificData,
    ProjectSurveyDevice,
    ProjectPanelChannel,
    ProjectSurveyOptions,
  },
})
export default class Step2 extends mixins(RegistrationMixin, ValidationMixin) {
  @Prop() readonly project!: Step2Data;

  private demoGraphic: DemoGraphic | null = null;
  private country: string[] = [];
  private estimateIncidenceRate = 0;
  private estimateLengthOfInterview = 0;
  private completeCount = 0;
  private cpi = 0;
  private currency = '';
  private device: Device | null = null;
  private options: { preventDuplicates: boolean } = { preventDuplicates: false };
  private supplier: string[] = [];

  async created(): Promise<void> {
    this.loading = true;
    this.regBtnDisabled = true;

    await this.load();

    this.loading = false;
    this.regBtnDisabled = false;
  }

  async load(): Promise<void> {
    try {
      const {
        demoGraphic,
        country,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        completeCount,
        device,
        options,
        supplier,
      } = this.project;

      this.demoGraphic = demoGraphic;
      this.country = country;
      this.estimateLengthOfInterview = estimateLengthOfInterview;
      this.estimateIncidenceRate = estimateIncidenceRate;
      this.completeCount = completeCount;
      this.device = device;
      this.options = options;
      this.supplier = Object.keys(supplier || {});
    } catch (e) {
      this.validationMessages(e);
    }
  }

  async submit() {
    this.proc(true);
    let submitResult = false;

    try {
      const {
        completeCount,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        cpi,
        currency,
        demoGraphic,
        device,
        supplier,
        options,
      } = this;

      const { result, data, message } = await this.axios.patch(`/project/step2/${ this.surveyId }`, {
        cpi,
        currency,
        completeCount,
        estimateIncidenceRate,
        estimateLengthOfInterview,
        demoGraphic,
        device,
        options,
        channel: supplier,
      });

      this.invalidMessages.splice(0, this.invalidMessages.length);

      for (const [key, value] of Object.entries(message as InvalidMessages[])) {
        Vue.set(this.invalidMessages, key, value);
      }

      if (result) {
        this.$emit('update:project', data);
      } else {
        this.validationMessages(message);
      }
      submitResult = !!result;
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
    return submitResult;
  }

  calcCpi (data: { feasible: number; completeCount: number; cpi: number; currency: string; total: number; managementFee: number; quotaFullFee: number; terminateFee: number; }) {
    const { feasible, cpi, currency, completeCount, total, terminateFee, managementFee, quotaFullFee, } = data;
    console.log(data);
  }
}
