
import Component, { mixins } from 'vue-class-component';
import ProjectSurveyUrl from '@/pages/project/components/survey-url.vue'
import ProjectSurveyUrlTest from '@/pages/project/components/survey-url-test.vue'
import { ValidationMixin } from '@/mixins/validation-mixin';
import ProgressButton from '@/pages/project/registration/progress-button.vue';
import { RegistrationMixin } from '@/mixins/registration-mixin';

@Component({
  components: {
    ProgressButton,
    ProjectSurveyUrl,
    ProjectSurveyUrlTest,
  }
})
export default class Step4 extends mixins(RegistrationMixin, ValidationMixin) {
  private agree = false;

  async submit() {
    const { url, urlTest } = this.$refs as { url: ProjectSurveyUrl, urlTest: ProjectSurveyUrlTest }
    let result = await url.urlSave(true);

    if (result && this.isExternalUser) {
      await urlTest.testStatusCheck();
    }

    return !!result;
  }

  async next() {
    const result = await this.submit();
    if (result) {
      await this.$router.replace(`/project/created/home/${ this.surveyId }`);
      this.regStep = 0;
      this.regSurveyId = '';
    }
  }

  async prev() {
    this.regStep = this.regStep - 1;
    this.toTop();
  }
}
