
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { InvalidMessages } from '@/mixins/validation-mixin';

@Component
export default class ProjectPanelChannel extends Vue {
  @Prop() invalidMessages!: Array<InvalidMessages>;
  @Prop() invalidMessage!: (field: string) => string | null;
  @Prop() validation!: (field: string) => boolean | null;
  @PropSync('supplier') supplierSync!: string[];

  supplierList = [];

  async created() {
    this.loading = true;
    await this.getSupplier();
    this.loading = false;
  }

  async getSupplier() {
    try {
      const { result, data, message } = await this.axios.get(`/project/enums/${ this.surveyId }`);

      if (!result) throw message;

      const { supplier } = data;
      this.supplierList = supplier;
    } catch (e) {
      this.validationMessages(e);
    }
  }
}
